<template>
  <div v-if="!loading" class="stats-container">
    <div class="placeholder-message" v-if="poolStatus != 'COMPLETED'">
      <h4>No stats to show yet</h4>
      <p>
        Once this {{ round.pools.length > 1 ? "pool" : "round" }} is finished
        the stats will show up.
      </p>
    </div>
    <span v-else>
      <div class="stats-header">Score stats</div>
      <div class="statistics-container">
        <table>
          <thead>
            <tr>
              <th class="stat-names"></th>
              <th v-for="hole in poolData" :key="hole.number" class="landscape">
                {{ hole.number }}
                <p>P{{ hole.par }}</p>
              </th>
              <th class="sums total-percent">
                Percent
                <p class="d-sm-block">&nbsp;</p>
              </th>
              <th class="sums total-count">
                Count
                <p class="d-sm-block">&nbsp;</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="aces">
              <td class="stat-names">Aces</td>
              <td
                v-for="hole in poolData"
                :key="hole.number"
                :class="{ active: hole.aces.count > 0 }"
                class="landscape"
              >
                <template v-if="showValues">
                  {{ showValue(hole.aces.count) }}
                </template>
                <template v-else>
                  {{ showPercentage(hole.aces.part) }}
                </template>
              </td>
              <td class="total-percent">
                {{ showPercentage(poolStats.aces.part, true) }}
              </td>
              <td class="total-count">{{ poolStats.aces.count }}</td>
            </tr>

            <tr class="eagles">
              <td class="stat-names">Eagles</td>
              <td
                v-for="hole in poolData"
                :key="hole.number"
                :class="{ active: hole.eagles.count > 0 }"
                class="landscape"
              >
                <template v-if="showValues">
                  {{ showValue(hole.eagles.count) }}
                </template>
                <template v-else>
                  {{ showPercentage(hole.eagles.part) }}
                </template>
              </td>
              <td class="total-percent">
                {{ showPercentage(poolStats.eagles.part, true) }}
              </td>
              <td class="total-count">{{ poolStats.eagles.count }}</td>
            </tr>

            <tr class="birdies">
              <td class="stat-names">Birdies</td>
              <td
                v-for="hole in poolData"
                :key="hole.number"
                :class="{ active: hole.birdies.count > 0 }"
                class="landscape"
              >
                <template v-if="showValues">
                  {{ showValue(hole.birdies.count) }}
                </template>
                <template v-else>
                  {{ showPercentage(hole.birdies.part) }}
                </template>
              </td>
              <td class="total-percent">
                {{ showPercentage(poolStats.birdies.part, true) }}
              </td>
              <td class="total-count">{{ poolStats.birdies.count }}</td>
            </tr>

            <tr class="pars">
              <td class="stat-names">Pars</td>
              <td
                v-for="hole in poolData"
                :key="hole.number"
                :class="{ active: hole.pars.count > 0 }"
                class="landscape"
              >
                <template v-if="showValues">
                  {{ showValue(hole.pars.count) }}
                </template>
                <template v-else>
                  {{ showPercentage(hole.pars.part) }}
                </template>
              </td>
              <td class="total-percent">
                {{ showPercentage(poolStats.pars.part, true) }}
              </td>
              <td class="total-count">{{ poolStats.pars.count }}</td>
            </tr>

            <tr class="bogeys">
              <td class="stat-names">Bogeys</td>
              <td
                v-for="hole in poolData"
                :key="hole.number"
                :class="{ active: hole.bogeys.count > 0 }"
                class="landscape"
              >
                <template v-if="showValues">
                  {{ showValue(hole.bogeys.count) }}
                </template>
                <template v-else>
                  {{ showPercentage(hole.bogeys.part) }}
                </template>
              </td>
              <td class="total-percent">
                {{ showPercentage(poolStats.bogeys.part, true) }}
              </td>
              <td class="total-count">{{ poolStats.bogeys.count }}</td>
            </tr>

            <tr class="dbogeys">
              <td class="stat-names">Double B</td>
              <td
                v-for="hole in poolData"
                :key="hole.number"
                :class="{ active: hole.doubleBogeys.count > 0 }"
                class="landscape"
              >
                <template v-if="showValues">
                  {{ showValue(hole.doubleBogeys.count) }}
                </template>
                <template v-else>
                  {{ showPercentage(hole.doubleBogeys.part) }}
                </template>
              </td>
              <td class="total-percent">
                {{ showPercentage(poolStats.doubleBogeys.part, true) }}
              </td>
              <td class="total-count">{{ poolStats.doubleBogeys.count }}</td>
            </tr>

            <tr class="tbogeys">
              <td class="stat-names">Triple B</td>
              <td
                v-for="hole in poolData"
                :key="hole.number"
                :class="{ active: hole.trippleBogeysAbove.count > 0 }"
                class="landscape"
              >
                <template v-if="showValues">
                  {{ showValue(hole.trippleBogeysAbove.count) }}
                </template>
                <template v-else>
                  {{ showPercentage(hole.trippleBogeysAbove.part) }}
                </template>
              </td>
              <td class="total-percent">
                {{ showPercentage(poolStats.trippleBogeysAbove.part, true) }}
              </td>
              <td class="total-count">
                {{ poolStats.trippleBogeysAbove.count }}
              </td>
            </tr>

            <tr class="average">
              <td class="stat-names">Average result</td>
              <td v-for="hole in poolData" :key="hole.number" class="landscape">
                {{ hole.averageScore }}
              </td>
              <td class="blank"></td>
              <td class="blank">{{ totalAverageScore() }}</td>
            </tr>

            <tr class="difficulty d-lg-table-row">
              <td class="stat-names">Difficulty</td>
              <td v-for="hole in poolData" :key="hole.number">
                {{ showDifficulty(hole.number) }}
              </td>
              <td class="blank"></td>
              <td class="blank"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="toggle-options d-xl-block">
        Display hole-by-hole as:
        <span
          @click="showValues = false"
          :class="{ active: showValues == false }"
          >Percent</span
        >
        <span @click="showValues = true" :class="{ active: showValues == true }"
          >Count</span
        >
      </div>
      <div class="stats-header">Precision stats</div>
      <div class="statistics-container">
        <table>
          <thead>
            <tr>
              <th class="stat-names"></th>
              <th v-for="hole in poolData" :key="hole.number" class="landscape">
                {{ hole.number }}
                <p>P{{ hole.par }}</p>
              </th>
              <th class="sums total-percent">
                Percent
                <p class="d-sm-block">&nbsp;</p>
              </th>
              <th class="sums total-count">
                Count
                <p class="d-sm-block">&nbsp;</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="circlehits">
              <td class="stat-names">Circle hits</td>
              <td
                class="par-bg landscape"
                v-for="hole in poolData"
                :key="hole.number"
              >
                <template v-if="showValues">
                  {{ showValue(hole.circleHits.count) }}
                </template>
                <template v-else>
                  {{ showPercentage(hole.circleHits.part) }}
                </template>
              </td>
              <td class="total-percent">
                {{ showPercentage(poolStats.circleHits.part, true) }}
              </td>
              <td class="total-count">{{ poolStats.circleHits.count }}</td>
            </tr>
            <tr class="conversions">
              <td class="stat-names">Conversions</td>
              <td
                v-for="hole in poolData"
                :key="hole.number"
                class="par-bg landscape"
              >
                <template v-if="showValues">
                  {{ showValue(hole.circleHitConversions.count) }}
                </template>
                <template v-else>
                  {{ showPercentage(hole.circleHitConversions.part) }}
                </template>
              </td>
              <td class="total-percent">
                {{ showPercentage(poolStats.circleHitConversions.part, true) }}
              </td>
              <td class="total-count">
                {{ poolStats.circleHitConversions.count }}
              </td>
            </tr>
            <tr class="insideputts">
              <td class="stat-names">Inside putts</td>
              <td
                class="par-bg landscape"
                v-for="hole in poolData"
                :key="hole.number"
              >
                <template v-if="showValues">
                  {{ showValue(hole.insidePutts.count) }}
                </template>
                <template v-else>
                  {{ showPercentage(hole.insidePutts.part) }}
                </template>
              </td>
              <td class="total-percent">
                {{ showPercentage(poolStats.insidePutts.part, true) }}
              </td>
              <td class="total-count">{{ poolStats.insidePutts.count }}</td>
            </tr>
            <tr class="outsideputts">
              <td class="stat-names">Outside putts</td>
              <td
                v-for="hole in poolData"
                :key="hole.number"
                class="par-bg landscape"
              >
                {{ showValue(hole.outsidePutts.count) }}
              </td>
              <td class="total-percent"></td>
              <td class="total-count">{{ poolStats.outsidePutts.count }}</td>
            </tr>
            <tr class="obs">
              <td class="stat-names">OBs</td>
              <td
                class="par-bg landscape"
                v-for="hole in poolData"
                :key="hole.number"
              >
                <template v-if="showValues">
                  {{ showValue(hole.outOfBounds.count) }}
                </template>
                <template v-else>
                  {{ showPercentage(hole.outOfBounds.part) }}
                </template>
              </td>
              <td class="total-percent"></td>
              <td class="total-count">{{ poolStats.outOfBounds.count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="toggle-options d-xl-block">
        Display hole-by-hole as:
        <span
          @click="showValues = false"
          :class="{ active: showValues == false }"
          >Percent</span
        >
        <span @click="showValues = true" :class="{ active: showValues == true }"
          >Count</span
        >
      </div>
    </span>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "PublicEventPoolStats",
  data() {
    return {
      round: null,
      poolData: {},
      poolStats: {},
      showValues: false,
      difficultyList: [],
      loading: true,
      areGroupsPublic: false,
      poolStatus: "",
    };
  },
  watch: {
    $route: function () {
      this.loadData();
    },
  },
  methods: {
    poolDate(date) {
      return moment(date).format("DD MMMM [at] HH.mm");
    },
    showDifficulty(holeNumber) {
      return (
        this.difficultyList.findIndex((x) => x.holeNumber == holeNumber) + 1
      );
    },
    totalAverageScore() {
      let tempVal = 0;
      for (var i = 0; i < this.poolData.length; i++) {
        tempVal += this.poolData[i].averageScore;
      }
      return tempVal.toFixed(2);
    },
    showValue(value) {
      if (value > 0) {
        return value;
      } else {
        return "";
      }
    },
    showPercentage(value, bool) {
      if (value > 0) {
        return Math.round(value * 100) + "%";
      } else {
        if (bool) {
          return "0%";
        } else {
          return "";
        }
      }
    },
    formattedGraphData(type) {
      let percentage = Math.round(this.poolData[type].part * 100);
      let count = this.poolData[type].count;
      let stat = {
        count: count,
        part: [percentage, 100 - percentage],
      };

      return stat;
    },
    loadData() {
      this.$axios({
        data: {
          query: `
          {
            pool(poolId:"${this.$router.currentRoute.params.poolId}"){
              status
              areGroupsPublic
              date
              round{
                pools{
                  id
                }
              }
              stats{
                aces{
                  count
                  part
                }
                eagles{
                  count
                  part
                }
                birdies{
                  count
                  part
                }
                pars{
                  count
                  part
                }
                bogeys{
                  count
                  part
                }
                doubleBogeys{
                  count
                  part
                }
                trippleBogeysAbove{
                  count
                  part
                }
                circleHits{
                  count
                  part
                }
                circleHitConversions{
                  count
                  part
                }
                insidePutts{
                  count
                  part
                }
                outsidePutts{
                  count
                  part
                }
                outOfBounds{
                  count
                  part
                }
              }
              holeStats{
                id
                number
                par
                averageScore
                aces{
                  count
                  part
                }
                eagles{
                  count
                  part
                }
                birdies{
                  count
                  part
                }
                pars{
                  count
                  part
                }
                bogeys{
                  count
                  part
                }
                doubleBogeys{
                  count
                  part
                }
                trippleBogeysAbove{
                  count
                  part
                }
                circleHits{
                  count
                  part
                }
                circleHitConversions{
                  count
                  part
                }
                insidePutts{
                  count
                  part
                }
                outsidePutts{
                  count
                  part
                }
                outOfBounds{
                  count
                  part
                }
              }
            }
          }
          `,
        },
      }).then((result) => {
        this.round = result.data.data.pool.round;
        this.poolData = result.data.data.pool.holeStats;
        this.poolStats = result.data.data.pool.stats;
        this.poolStatus = result.data.data.pool.status;
        this.areGroupsPublic = result.data.data.pool.areGroupsPublic;
        this.startDate = result.data.data.pool.date;
        this.difficultyList = [];

        for (var i = 0; i < this.poolData.length; i++) {
          let hole = {
            holeNumber: this.poolData[i].number,
            fromPar:
              Math.round(
                (this.poolData[i].par - this.poolData[i].averageScore) * 100
              ) / 100,
          };
          this.difficultyList.push(hole);
        }

        this.difficultyList.sort((a, b) => a.fromPar - b.fromPar);

        if (typeof this.poolStats !== "undefined") {
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped lang="scss">
.placeholder-message {
  margin-top: 50px;
  text-align: center;
  h4 {
    font-size: 20px;
    margin-bottom: 16px;
    margin-top: 0;
  }
  p {
    font-size: 16px;
    color: $strom;
  }
}
.stats-header {
  height: 30px;
  @include Gilroy-Bold;
  background-color: $mist;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: center;
  font-size: 14px;
}
.message {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 26px;
  font-size: 16px;
  @include Gilroy-Regular;

  button {
    background-color: $peach;
    color: white;
    border: 0px;
    height: 40px;
  }

  b {
    @include Gilroy-Bold;
  }
}
h4 {
  margin: 30px;
}

.statistics-container {
  overflow: scroll;
  background-color: white;
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 0px 20px 0px 20px;
  width: 100%;
  background-color: white;
}
table {
  min-width: 900px;
  width: 100%;

  th {
    background-color: white;
    &.total-count {
      width: 8%;
      padding: 0;
    }
    &.total-percent {
      width: 8%;
      text-align: center;
      padding: 0;
    }
    &.stat-names {
      width: 15%;
      text-align: left;
      padding-right: 15px;
    }
    &.total-percent {
      width: 25%;
      text-align: center;
    }
    &.total-count {
      width: 25%;
      text-align: center;
      padding-left: 20px;
    }
    &.sums {
      text-transform: uppercase;
    }

    p {
      @include Gilroy-Regular;
      font-size: 12px;
      margin: 0;
    }

    &.stat-names {
      width: 40%;
      text-align: left;
    }
  }
  td {
    @include Gilroy-Regular;
    text-align: center;
    font-size: 16px;
    border: 1px solid white;
    height: 40px;
    border-bottom: 1px solid $halfmouse;

    &.total-count {
      @include Gilroy-Regular;
    }
    &.total-count {
      width: 25%;
      text-align: center;
      padding-left: 20px;
    }
    &.blank {
      @include Gilroy-Regular;
    }
    &.total-percent {
      text-align: center;
      @include Gilroy-Regular;
    }
    &.stat-names {
      text-align: left;
      @include Gilroy-Bold;
    }
  }
}

.toggle-options {
  font-size: 12px;
  margin-bottom: 44px;
  margin-left: 20px;
  cursor: pointer;

  span {
    position: relative;

    @include Gilroy-Bold;
    margin: 0 10px;
    display: inline-flex;
    width: auto;

    &.active {
      &:after {
        content: "";
        position: absolute;
        width: 70%;
        left: 15%;
        bottom: -4px;
        height: 3px;
        background-color: $midnight;
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}

.toggle-options {
  font-size: 12px;
  margin-bottom: 44px;
  margin-left: 20px;

  span {
    position: relative;

    @include Gilroy-Bold;
    margin: 0 10px;
    display: inline-flex;
    width: auto;

    &.active {
      &:after {
        content: "";
        position: absolute;
        width: 70%;
        left: 15%;
        bottom: -4px;
        height: 3px;
        background-color: $midnight;
      }
    }
  }
}

.statistics-container {
  overflow: scroll;
  background-color: $halfmist;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 0;
  width: 100%;
  background-color: white;
}
.stats-header {
  margin-top: 0;
  height: 40px;
  font-size: 18px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include Gilroy-Bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
table {
  width: 100%;
  table-layout: fixed;

  tr {
    background-color: $halfmist;
    &.aces {
      .active {
        background-color: $ace;
      }
    }
    &.eagles {
      .active {
        background-color: $eagle;
      }
    }
    &.birdies {
      .active {
        background-color: $birdie;
      }
    }
    &.pars {
      .active {
        background-color: $par;
      }
    }
    &.bogeys {
      .active {
        background-color: $bogey;
      }
    }
    &.dbogeys {
      .active {
        background-color: $doublebogey;
      }
    }

    &.tbogeys {
      .active {
        background-color: $tripplebogey;
      }
    }
  }
  thead {
    th {
      text-align: center;
      font-size: 15px;
      @include Gilroy-Bold;
      height: 70px;
      background-color: $halfmist;

      &.sums {
        text-transform: uppercase;
      }

      p {
        @include Gilroy-Medium;
        font-size: 12px;
        margin: 0;
      }
      &.total-count {
        width: 8%;
        padding: 0;
      }
      &.total-percent {
        width: 8%;
        text-align: center;
        padding: 0;
      }
      &.stat-names {
        width: 15%;
        text-align: left;
        padding-right: 15px;
      }
    }
  }
  tbody {
    .average,
    .tbogeys,
    .difficulty {
      td {
        border: 0px solid white;
        border-bottom: 1px solid $halfmouse;
        font-size: 15px;
      }
    }
    td {
      @include Gilroy-Regular;
      text-align: center;
      font-size: 12px;
      border: 1px solid white;
      height: 50px;
      border: 1px solid white;
      background-color: $halfmist;

      &.par-bg {
        background-color: $halfmist;
        border: 1px solid $halfmouse;
      }
      &.total-percent {
        text-align: center;
        padding: 0;
        background-color: $mouse;
        @include Gilroy-Bold;
      }
      &.total-count {
        text-align: center;
        padding: 0;
        background-color: $wolf;
        @include Gilroy-Bold;
      }
      &.stat-names {
        @include Gilroy-Bold;
        background-color: $halfmist;
        text-align: left;
        padding-left: 20px;
        border: 0px solid white;
        border-bottom: 1px solid $halfmouse;
        font-size: 15px;
      }
      &.blank {
        background-color: $halfmist;
        @include Gilroy-Bold;
      }
    }
  }
}
@media (min-width: 1200px) {
  .statistics-container {
    overflow: unset;
  }
  .placeholder-message {
    text-align: center;
    h4 {
      font-size: 24px;
      margin-bottom: 24px;
    }
    p {
      font-size: 18px;
      color: $strom;
    }
  }
}
</style>
